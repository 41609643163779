import React from "react";
import { Props } from "./types";

export const Card = ({ image, title, description }: Props) => {
  return (
    <div>
      <div className="w-full max-w-sm bg-white rounded-lg shadow">
        <div className="p-4">
          <img
            draggable={false}
            className="w-24  rounded-t-lg"
            src={image}
            alt="image"
          />
        </div>
        <div className="px-5 pb-5">
          <h5 className="text-xl text-left tracking-tight text-[#3D538C] font-bold">
            {title}
          </h5>
          <p className="text-sm text-left leading-6 tracking-tight text-[#8B9BA3] font-light">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
