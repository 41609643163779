import { Props } from "./types";

export const QuestionSummary = ({ title, content, link }: Props) => {
  return (
    <div className="mb-4">
      <details className="group">
        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex justify-center items-center bg-[#F98065] h-10 w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ffffff"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </div>
            </div>
            <span className="ml-4 text-sm text-left font-bold text-[#3A538C]">
              {title}
            </span>
          </div>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shape-rendering="geometricPrecision"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <p className="text-[#70838A] ml-14 text-left font-light mt-3 group-open:animate-fadeIn">
          {content} <span className="font-medium">{link ? link : ''}</span>
        </p>
      </details>
    </div>
  );
};
