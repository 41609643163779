import React from "react";

export const Footer = () => {
  return (
    <div>
      <footer className="">
        <div className="w-full mx-auto p-4 md:py-8">
          <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
          <span className="block text-sm text-gray-500 sm:text-center ">
            © {new Date().getFullYear()}{" "}
            <a href="https://wibof.com/" className="hover:underline">
              nimo group LLC.
            </a>{" "}
            todos los derechos reservados.
          </span>
        </div>
      </footer>
    </div>
  );
};
