import React from "react";
import AppleStore from "../../assets/images/stores/app-store.png";
import GooglePlay from "../../assets/images/stores/google-play.png";
import Feature1 from "../../assets/images/specialists/feature-1.png";
import Feature2 from "../../assets/images/specialists/feature-2.png";
import Feature3 from "../../assets/images/specialists/feature-3.png";
import Feature4 from "../../assets/images/specialists/feature-4.png";
import BannerPhone from "../../assets/images/banner-phone.png";
import QuestionsImage from "../../assets/images/questions-image.jpg";
import Number1 from "../../assets/images/numbers/number1.png";
import Number2 from "../../assets/images/numbers/number2.png";
import Number3 from "../../assets/images/numbers/number3.png";
import Number4 from "../../assets/images/numbers/number4.png";
import LeftCircle from "../../assets/images/left-circle.png";
import Header from "../../components/Header";
import FeatureItem from "../../components/1.Atoms/FeatureItem";
import { Bounce, Fade } from "react-awesome-reveal";
import { QuestionSummary } from "../../components/2.Molecules/QuestionSummary";
import { Footer } from "../../components/2.Molecules/Footer";

const Specialists = () => {
  return (
    <>
      <div>
        <Header />
        <section id="home" className="specialists-bg">
          <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
            <div className="lg:mt-0 md:mt-0 mt-14 flex flex-col items-center mb-16 text-center lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
              <h2 className="mt-14 text-center lg:mb-4 lg:text-4xl text-lg font-light text-white">
                Wibof para Terapeutas:
              </h2>
              <h1 className="mb-8 text-2xl lg:text-[3.2rem] lg:leading-[60px] font-regular text-[#FE805F]">
                Tu Compañero Integral de Gestión
              </h1>
              <p className="mb-8 lg:text-base text-sm font-medium leading-relaxed text-center text-white">
                Juntos, optimizamos tu gestión y mejoramos la experiencia de tus
                pacientes. ¡Únete a nuestra comunidad hoy mismo!
              </p>
            </div>
          </div>
        </section>
        <Fade>
          <section className="lg:-mt-[70rem] md:-mt-[70rem] -mt-[72rem]">
            <div className="p-2">
              <h2 className="lg:text-[3.2rem] text-[2rem] font-regular tracking-tighter text-[#FE805F]">
                Tu aliado en la práctica terapéuticas
              </h2>
              <div className="flex justify-center">
                <p className="mt-4 mb-8 lg:text-base max-w-[600px] text-sm font-medium leading-relaxed text-center text-[#3A538C]">
                  Wibof es tu aliado tecnológico diseñado para simplificar la
                  gestión de tu práctica terapéutica. Te proporcionamos
                  herramientas y recursos que optimizan tu trabajo diario,
                  permitiéndote centrarte en lo más importante: el bienestar de
                  tus pacientes. Con Wibof, mejorar la experiencia de quienes
                  confían en ti nunca ha sido tan fácil.
                </p>
              </div>
            </div>
          </section>
        </Fade>

        <div>
          <div id="features">
            <div className="flex justify-center p-4">
              <div className="">
                <Fade delay={100}>
                  <section className="bg-white">
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-[32rem]"
                        src={Feature1}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        {/* <h2 className="mb-4 text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                          Pacientes
                        </h2>
                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" /> */}
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Centralización de información: Ten toda la información de tus pacientes y citas en un solo lugar."
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={
                              "Gestión de citas: Sincroniza tu calendario y organiza tus citas de manera eficiente."
                            }
                          />
                          <FeatureItem
                            number={Number3}
                            description={
                              "Seguimiento de pacientes: Realiza un seguimiento completo de tus pacientes entre citas, utilizando registros de humor y notas personalizadas para monitorear su progreso."
                            }
                          />
                          <FeatureItem
                            number={Number4}
                            description={
                              "Administración financiera: Administra tus finanzas y revisa tu historial de pagos de forma sencilla."
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Fade>
                <Fade delay={100}>
                  <section className="bg-white">
                    <div className="grid grid-cols-2 gap-4 justify-between items-center">
                      <div>
                        <img
                          draggable={false}
                          className="w-[32rem]"
                          src={Feature2}
                          alt="dashboard image"
                        />
                        <h2 className="lg:text-[1.4rem] text-[1rem] max-w-md font-regular tracking-tighter text-[#FE805F]">
                          Podrás gestionar citas con sincronización de
                          calendario y disponibilidad.
                        </h2>
                      </div>
                      <div>
                        <img
                          draggable={false}
                          className="w-[32rem]"
                          src={Feature3}
                          alt="dashboard image"
                        />
                        <h2 className="lg:text-[1.4rem] text-[1rem] max-w-md font-regular tracking-tighter text-[#FE805F]">
                          Seguimiento con el paciente entre citas.
                        </h2>
                      </div>
                    </div>
                    <div className="mt-6 w-full flex justify-center">
                      <div>
                        <img
                          draggable={false}
                          className="w-[32rem]"
                          src={Feature4}
                          alt="dashboard image"
                        />
                        <h2 className="lg:text-[1.4rem] text-[1rem] max-w-md font-regular tracking-tighter text-[#FE805F]">
                          Administra tus finanzas personales y accede a tu lista
                          de pacientes.
                        </h2>
                      </div>
                    </div>
                    {/* <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-72 lg:hidden md:hidden block"
                        src={SpecialistPhone}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                          Psicólogos
                        </h2>
                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Toda tu práctica terapéutica, centralizada en un mismo lugar"
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={"Acompaña a tus pacientes"}
                          />
                          <FeatureItem
                            number={Number3}
                            description={
                              "Conecta con nuevos pacientes que buscan apoyo"
                            }
                          />
                          <FeatureItem
                            number={Number4}
                            description={
                              "Simplifica tu trabajo con herramientas como calendario de citas, cobro automatizado, y paneles informativos"
                            }
                          />
                        </div>
                      </div>
                      <img
                        draggable={false}
                        className="w-72 hidden lg:block"
                        src={SpecialistPhone}
                        alt="dashboard image"
                      />
                    </div> */}
                  </section>
                </Fade>
                {/* <Fade delay={100}>
                  <section className="bg-white">
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-72"
                        src={CompanyPhone}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                          Instituciones
                        </h2>
                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Con Wibof, podrás medir la motivación y bienestar de tus empleados"
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={
                              "Identifica y evalúa síntomas de burnout y del estado emocional de tu equipo"
                            }
                          />
                          <FeatureItem
                            number={Number3}
                            description={
                              "Obtén información estadística sobre la salud mental de tus colaboradores para crear un ambiente laboral más positivo"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Fade> */}
              </div>
            </div>
          </div>

          <section className="manada-bg flex flex-col lg:flex-row items-center text-white py-16 lg:py-20 px-4 lg:px-6">
            <div className="lg:absolute left-28 sm:mt-4 lg:text-left lg:mb-0">
              <h2 className="lg:mb-10 text-3xl lg:text-[5rem] font-light">
                ¡unete a
              </h2>
              <h1 className="text-4xl lg:text-[5rem] font-medium text-[#FE805F]">
                mi manada!
              </h1>
              <p className="lg:mt-10 lg:text-md text-sm font-medium text-white">
                En ella, podrás compartir tus sentimientos y recibir apoyo: todo
                de manera anónima y segura.
              </p>
            </div>
          </section>

          <section id="questions" className="bg-[#F4F6F5] lg:-mt-16">
            <div className="absolute right-0">
              <img
                draggable={false}
                src={LeftCircle}
                alt="Imagen Izquierda"
                className="absolute z-10 top-1/2 transform w-52 h-auto lg:relative lg:left-auto"
              />
            </div>
            <div className="gap-4 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
              <img draggable={false} src={QuestionsImage} alt="Wibof" />

              <div className="mt-4 md:mt-0">
                <h1 className="lg:text-left lg:mb-8 lg:text-[3rem] text-[1.6rem] font-medium text-[#3A538C] md:text-5xl">
                  Preguntas <br />
                  frecuentes
                </h1>

                <div>
                  <div className="py-5">
                    <QuestionSummary
                      title={
                        "¿Cuáles son los requisitos para convertirse en terapeuta en la plataforma?"
                      }
                      content={
                        "Para unirte como terapeuta en nuestra plataforma, debes cumplir con requisitos básicos de licenciatura y experiencia en salud mental. Por favor, completa nuestro formulario en "
                      }
                      link={
                        <a
                          href="https://forms.gle/GxsxkWCoAdVVUjPS8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://forms.gle/GxsxkWCoAdVVUjPS8
                        </a>
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cómo puedo actualizar mi perfil y mi información profesional?"
                      }
                      content={
                        "Puedes encontrar tu perfil y tu información profesional en la sección 'Mi perfil' de la aplicación. Aquí podrás agregar detalles sobre tu experiencia, habilidades y enfoques terapéuticos."
                      }
                    />
                    <QuestionSummary
                      title={"¿Cómo puedo reactivar mi cuenta de terapeuta?"}
                      content={
                        "Puedes reactivar tu cuenta enviando un correo a soporte@wibof.com Estaremos encantados de ayudarte a volver a utilizar nuestros servicios."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-0 rotate-180">
              <img
                draggable={false}
                src={LeftCircle}
                alt="Imagen Izquierda"
                className="absolute z-10 top-[354px] transform w-52 h-auto lg:relative lg:left-auto"
              />
            </div>
          </section>
          <Bounce>
            <div
              id="start"
              className="lg:mt-[8rem] w-full flex justify-center items-center"
            >
              <section className="w-full flex items-center max-w-screen-lg  lg:h-[20rem] bg-[#FFE2DA] rounded-xl">
                <div className="gap-24 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                  <div className="">
                    <h2 className="mb-4 lg:text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                      juntos avancemos
                    </h2>
                    <h2 className="mb-4 lg:text-left text-4xl tracking-tight font-bold text-[#F97F64]">
                      hacia tu mejor versión
                    </h2>
                    <div className="lg:text-left">
                      <small className="mb-4 lg:text-left text-sm tracking-tight font-medium text-[#3A538C]">
                        ¡Descárgala hoy mismo y comencemos!
                      </small>
                      <div className="w-full mt-2 flex flex-col mb-4 lg:flex-row">
                        <div className="w-full flex lg:justify-start justify-center items-center gap-4">
                          <a
                            href="https://apps.apple.com/do/app/wibof-terapia-psicologica/id6473286882?l"
                            rel="noreferrer"
                            className="app-download-btn"
                          >
                            <img
                              draggable={false}
                              className="w-32"
                              src={AppleStore}
                              alt=""
                            />
                          </a>
                          <a
                            type="button"
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.wibof.app"
                            rel="noreferrer"
                          >
                            <img
                              draggable={false}
                              className="w-36"
                              src={GooglePlay}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    draggable={false}
                    className="w-80"
                    src={BannerPhone}
                    alt="dashboard image"
                  />
                </div>
              </section>
            </div>
          </Bounce>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Specialists;
