import React from "react";

export const TermsAndConditions = () => {
  return (
    <div className="p-[1rem] md:p-0 lg:p-0">
      <div className="mt-12">
        <h1 className="text-lg font-bold">
          Términos y condiciones para el Uso de Nimo
        </h1>
      </div>
      <div className="flex justify-center">
        <div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">1. Sobre Nosotros</h1>
            <p className="font-light text-justify">
              WIBOF APP/WEB es una plataforma operada por NIMO GROUP LLC, (en lo
              adelante “NIMO”, “nosotros”, “nuestro/a”), entidad constituida
              bajo las leyes del Estado de Delaware, con domicilio en 1007 N
              Orange St., 4th Floor, suite 1382, Wilmington, Delaware, 19801.
              Los presentes términos y condiciones (“Términos”), establecen las
              formas y modalidades de utilización de los servicios ofrecidos a
              través de la aplicación móvil (“App”), y constituye contrato
              vinculante entre el Usuario (“tú”, usted, “Usuario/s”) y el
              terapeuta registrado (“terapeuta”), por una parte y WIBOF por otra
              parte. Si tienes cualquier duda relacionada con los Términos, por
              favor contáctanos a través de la dirección de correo electrónico
              soporte@wibof.com . Lea detenidamente los presentes Términos y
              condiciones de uso, previo realización de su registro en la
              plataforma y la contratación de los servicios. Al acceder y/o
              utilizar de cualquier forma la “App”, reconoces haber leído,
              entendido y aceptado los presentes Términos y condiciones, en tú
              propio nombre o en nombre de la compañía u organización que
              representas. En el supuesto que no estés de acuerdo con los
              presentes Términos, absténgase de acceder o utilizar la “App”.
              Para una mejor comprensión de uso y contenido de la plataforma,
              estas palabras y expresiones tendrán los siguientes significados:
              <ol>
                <li>
                  1. <small className="font-bold">"plataforma"</small> describe
                  tanto la aplicación App de WIBOF como la página web
                  www.wibof.com
                </li>
                <li>
                  2. <small className="font-bold">"Sicólogo",</small>{" "}
                  profesional de la salud capacitado y habilitado para ejercer
                  la sicología y brindar servicios de terapia sicológica.
                </li>
                <li>
                  3. <small className="font-bold">“Usuario",</small> persona
                  física que hace uso de la plataforma, a título personal o de
                  una empresa, corporación o cualquier otro tipo de entidad con
                  personería jurídica. Para la interpretación de los presentes
                  términos, al hacer referencia al registro en la plataforma,
                  derechos y deberes de los usuarios, el término “usuario”
                  aplica para todas las personas registradas en la plataforma
                  para brindar o recibir servicios, por lo que se aplica tanto a
                  pacientes como a sicólogos; al hacer referencia a la relación
                  sicólogo-paciente, el término “usuario” se interpretará como
                  sinónimo de “paciente”.
                </li>
                <li>
                  4. <small className="font-bold">"Paciente",</small> persona
                  física, usuario o no de la plataforma, que consulta y requiere
                  los servicios de terapia ofrecidos por los Sicólogos
                  registrados en la plataforma.
                </li>
              </ol>
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">
              2. Servicios ofrecidos a través de la “App”
            </h1>
            <p className="font-light text-justify">
              WIBOF pone a disposición de usuarios y profesionales titulados en
              el campo de la psicología un entorno tecnológico confiable para
              que puedan realizar citas, con la finalidad de que el terapeuta
              pueda facilitar atención psicológica y los usuarios puedan
              contactar con los Sicólogos de la plataforma, siempre dentro de un
              entorno privado y confidencial entre paciente y Sicólogo, tanto
              presencial como virtual. WIBOF no realiza ni realizará, en ningún
              caso, actividades de índole sanitaria, no intervendrá ni será
              parte en las comunicaciones que se establezcan entre paciente y
              Sicólogo. Todos los servicios prestados por los Sicólogos serán
              prestados en su propio nombre y representación y bajo su exclusiva
              responsabilidad. WIBOF es también una plataforma que se ocupa de
              que el usuario paciente encuentre el Sicólogo que necesita según
              la información brindada por el mismo. Mediante la recogida de esta
              información y el llenado de un cuestionario, WIBOF se encarga de
              enlazar al paciente y al Sicólogo. El Sicólogo podrá seguir el
              progreso del paciente con la información que éste decida
              compartirle. Asimismo, el Sicólogo podrá asignar tareas de
              distintos tipos y gestionar las mismas a través de la aplicación,
              bajo su absoluta responsabilidad. WIBOF proporciona al usuario una
              comunidad de interacción denominada “manada”, (en lo adelante “ la
              manada”) donde los usuarios pueden comunicarse de forma anónima.
              El usuario reconoce que su acceso a “la manada” es voluntario, por
              lo que toda la información compartida en la manada es de su
              absoluta responsabilidad. Igualmente el usuario se hace
              responsable de su comportamiento e interacciones en “la manada”, y
              reconoce que podrá ser excluido de la comunidad, en caso de no
              observar un comportamiento moral adecuado y sus reglas de uso. En
              caso de comportamiento inapropiado, todo usuario de la manada
              podrá reportarlo a soporte@wibof.com . WIBOF se compromete a
              tomar las medidas adecuadas para regular el comportamiento de los
              usuarios de “la manada”, que pueden incluir una sanción de
              expulsión. Si usted considera que su caso requiere de diagnóstico
              o tratamiento presencial le recomendamos acudir a su centro médico
              de preferencia para un correcto análisis y diagnóstico. La
              Plataforma no está diseñada para dichas actividades ni para
              actividades de prescripción de medicamentos o actividades
              análogas. Sin embargo, usted puede realizar una cita a través de
              la plataforma para una consulta presencial con su Sicólogo. WIBOF
              recomienda al Usuario que, ante la más mínima duda acerca de su
              situación, acuda directamente a su centro de referencia o al
              centro de urgencias más próximo para que puedan tratar su caso en
              profundidad y con las medidas y herramientas necesarias. De igual
              manera, recomendamos al Usuario acudir a su centro médico o de
              urgencias si considera que su salud física o mental, o la de
              terceros con los que tenga relación, puedan estar en peligro. Dado
              que WIBOF ofrece únicamente la Plataforma a Usuarios y Sicólogos,
              en el supuesto que un Usuario tenga alguna queja o reclamación
              acerca de uno o más Sicólogos, deberá dirigir la queja o
              reclamación directamente al Sicólogo en cuestión, al ser éste
              quien le presta los servicios de atención de forma directa. Sin
              embargo, si el Usuario se considera afectado por una mala práctica
              del Sicólogo, puede contactarnos a través del correo electrónico:
              <small className="underline">clinico@wibof.com.</small>
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">
              3. Uso de los servicios de la plataforma
            </h1>
            <p className="font-light text-justify">
              Para acceder a los servicios de WIBOF, tanto el Usuario como el
              Sicólogo deberán ser mayores de edad y tener plena capacidad legal
              de obrar, así como registrarse previamente a través del mecanismo
              de registro presente en cada momento en la “App”. El uso de la
              Plataforma por parte de menores está totalmente prohibido. WIBOF
              no tiene obligación alguna de verificar las informaciones,
              titulaciones, capacidades, habilidades o servicios de los
              Sicólogos. Sin perjuicio de lo anterior, el Sicólogo deberá
              aportar, de forma previa a su registro, la documentación que le
              sea requerida en cada momento, sin que ésta aportación de
              información pueda entenderse como un control de los requisitos del
              Sicólogo por parte de WIBOF. Los Usuarios y Sicólogos reconocen y
              aceptan que no se establecerá relación de dependencia entre WIBOF
              y los Sicólogos, quienes se registran para poder prestar
              libremente sus servicios de forma directa a los Usuarios sin
              relación laboral con WIBOF. Una vez registrado y validado, y de
              forma previa a la selección de uno o más Sicólogos, el Usuario
              podrá contestar un breve formulario para que WIBOF pueda facilitar
              mejores resultados a los Usuarios en base a sus preferencias o
              necesidades. En ningún caso el formulario previo contendrá ni
              solicitará datos personales relativos a la salud de los Usuarios
              ni podrá ser considerado como un examen médico en base a las
              necesidades del Usuario. El formulario es una simple herramienta
              para ordenar los resultados obtenidos de forma más beneficiosa
              para el Usuario en base a las especialidades definidas por cada
              Sicólogo. Una vez respondidas las preguntas iniciales, el Usuario
              podrá navegar entre el resultado de Sicólogos facilitado, pudiendo
              comprobar el perfil de cada uno de los Sicólogos de forma previa a
              iniciar la conversación. Una vez seleccionado el Sicólogo deseado,
              el Usuario podrá reservar una sesión según la disponibilidad que
              se muestre en cada momento por cada Sicólogo y podrá conocer los
              precios para cada sesión. Cuando el Usuario y el Sicólogo hayan
              accedido al entorno de comunicaciones, WIBOF no prestará servicio
              alguno salvo la Plataforma, con lo que las comunicaciones y
              servicios profesionales serán prestados directamente por cada
              Sicólogo, sin que WIBOF tenga un papel activo en estos,
              limitándose su actividad a la puesta a disposición del entorno
              para las comunicaciones. El Usuario y el Sicólogo podrán acordar
              el día para la sesión y acceder a la Plataforma a través de su
              interfaz de usuario en WIBOF. Tanto el Usuario como el Sicólogo
              reconocen y aceptan que utilizarán la Plataforma única y
              exclusivamente para la gestión de citas presenciales o la
              realización de visitas online, sin que pueda ser utilizada para el
              desarrollo de actividades médicas, diagnosis, tratamiento o
              cualquier otra actividad que requiera de cualificaciones no
              poseídas por los Sicólogo o de visitas presenciales. En estos
              supuestos, el Sicólogo se obliga a informar de este hecho al
              Usuario y ambos deberán abstenerse de utilizar la Plataforma para
              tales actividades. Es responsabilidad única y exclusiva del
              Sicólogo la utilización de la Plataforma según los criterios
              propios de su profesión y según los Términos de WIBOF.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">3.1 Restricciones de uso.</h1>
            <p className="font-light text-justify">
              Los Usuarios y Sicólogo deberán respetar los presentes Términos en
              todo momento mientras dura el Acuerdo. WIBOF podrá cancelar o
              suspender toda cuenta que incumpla los Términos o sobre las que
              WIBOF tenga duda o sospecha de incumplimiento. A modo de ejemplo,
              y sin que pueda entenderse como una lista cerrada, el Usuario y el
              Sicólogo se comprometen a:
              <ol>
                <li>
                  1. Utilizar los servicios de forma personal, por su propia
                  cuenta y bajo su propio nombre. Las credenciales de acceso de
                  cada Usuario y Sicólogo no podrán ser compartidos por más de
                  un (1) Usuario o Sicólogo. El titular de la cuenta es el único
                  responsable de seleccionar contraseñas seguras para el acceso
                  a los servicios, cambiarlas frecuentemente, mantener un nivel
                  de confidencialidad de las credenciales de acceso a los
                  perfiles y restringir el acceso a los servicios a usuarios no
                  autorizados. WIBOF no asume ninguna responsabilidad por
                  cualquier pérdida o daño sufrido en el supuesto de acceso no
                  autorizado a los perfiles de Usuarios y/o Sicólogo que tengan
                  su causa en un fallo en la protección de las credenciales de
                  acceso a la cuenta.
                </li>
                <li>
                  2. No utilizar la Plataforma para difundir contenidos que
                  atenten contra la moral y el orden público o que estén
                  prohibidos por las legislaciones de aplicación, o que puedan
                  vulnerar derechos de terceros o violar la confidencialidad de
                  las informaciones recibidas a través de los servicios
                  ofrecidos por WIBOF. Los Sicólogos no podrán utilizar ni
                  difundir las informaciones facilitadas por los Usuarios a
                  través de la Plataforma para finalidades distintas a las
                  comunicadas al Usuario o a WIBOF.
                </li>
              </ol>
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">3.2 Usos indebidos</h1>
            <p className="font-light text-justify">
              A pesar de que WIBOF no controla los usos que los Usuarios y
              Sicólogo hacen de la Plataforma, estos podrán comunicarse con
              WIBOF en el supuesto que tengan conocimiento de irregularidades o
              infracciones en el uso de la Plataforma a través del correo
              electrónico: soporte@wibof.com o a través de cualquier
              mecanismo de contacto previsto en la “App”. En este caso, WIBOF
              examinará las informaciones facilitadas y, sin encontrarse
              obligado a y sin que ello implique reconocimiento de
              responsabilidad alguna, podrá tomar las medidas que considere
              apropiadas al uso indebido denunciado, como cancelar o suspender
              las cuentas involucradas, denunciar los hechos antes las
              autoridades competentes, retirar los contenidos e informaciones
              denunciadas y/o suspender el acceso a la Plataforma, dependiendo
              de la gravedad.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">4. Tarifas y pago</h1>
            <p className="font-light text-justify">
              Los servicios contratados por parte de Usuarios y Sicólogos
              tendrán un coste asociado. Las modalidades de pago según sea
              Usuario o Sicólogos se describen a continuación:
            </p>
            <ul className="">
              <li className="font-light text-justify">
                1. Usuarios: Una vez el Usuario haya seleccionado el Sicólogo
                deseado, podrá seleccionar día y hora en el calendario que se
                mostrará a través de la “App”. Una vez seleccionada la sesión,
                el Usuario podrá proceder al pago a través de las siguientes
                opciones:
              </li>

              <ul className="ml-8">
                <li className="font-light text-justify">
                  a. Usuarios: Una vez el Usuario haya seleccionado el Sicólogo
                  deseado, podrá seleccionar día y hora en el calendario que se
                  mostrará a través de la “App”. Una vez seleccionada la sesión,
                  el Usuario podrá proceder al pago a través de las siguientes
                  opciones:
                </li>
                <br />

                <li className="font-light text-justify">
                  b. El Sicólogo reconoce y acepta que los pagos recibidos a
                  trávez de WIBOF serán acreditados a su cuenta en un plazo de
                  cinco (5) días laborales, ya que el mismo depende de la
                  agilidad con la que la plataforma de pago utilizada (stripe)
                  realice el desembolso. Sin embargo, el Sicólogo está
                  consciente de que pueden presentarse situaciones especiales,
                  ajenas a WIBOF, por las que este plazo pudiera llegar a diez
                  (10) días, incluyendo gestiones de las entidades de
                  intermediación financiera, días feriados, causas de fuerza
                  mayor, entre otras posibles situaciones que escapan del
                  control de WIBOF; en cuyo caso WIBOF hará su mejor esfuerzo
                  para la agilización de los procesos de pago.
                </li>
              </ul>
              <li className="font-light text-justify">
                2. Sicólogos. El Sicólogo, a la hora de registrarse y
                dependiendo del plan elegido, puede estar obligado a pagar una
                tarifa mensual por formar parte de la comunidad WIBOF. WIBOF
                también cobrará a los Sicólogos una comisión por los servicios
                de marketing que les permite acceder a nuevos clientes del
                mercado de WIBOF. En el caso de clientes que sean agregados por
                el Sicólogo por su propia cuenta, también se podrán cobrar los
                costos de las operaciones de pago. WIBOF facilita estos detalles
                de precios específicos en la hoja de precios.
                <br />
                De forma periódica, WIBOF remitirá la correspondiente factura al
                Sicólogo utilizando los datos de facturación que hayan sido
                facilitados en el proceso de registro o modificados por el
                terapeuta a través de su perfil.
              </li>
            </ul>
            <p className="font-light text-justify">
              En el supuesto que el Usuario y Sicólogo decidan hacer efectivo el
              pago a través de las pasarelas de pago dispuestas en la
              Plataforma, WIBOF informa que el sistema o pasarela de pagos
              utilizado en cada momento será propiedad y estará gestionado por
              un tercero, sin intervención de WIBOF, por lo que el Usuario y el
              Sicólogo deberán leer y aceptar los términos y condiciones del
              tercero de forma previa a su uso.
              <br />
              Con la contratación de los servicios, el Usuario y el Sicólogo se
              obligan a facilitar medios de pago válidos y legítimos y reconocen
              que tienen los derechos necesarios para proporcionar la
              información de pago y autoriza a WIBOF a que cargue las cantidades
              aceptadas.
              <br />
              WIBOF se reserva el derecho a modificar los precios y planes en
              cualquier momento, cambio que será comunicado a través del correo
              electrónico de contacto presente en el formulario de registro o a
              través del perfil del Administrador en nuestra Plataforma. La
              continuación en el uso de los servicios tras la comunicación
              supondrá la aceptación a los cambios comunicados.
              <br />
              El Usuario y el Sicólogo aceptan pagar el valor de los servicios a
              través de tarjeta de crédito/débito, domiciliación bancaria u otro
              medio de pago que esté habilitado a tal efecto en la Plataforma,
              pudiendo variar en el tiempo. Estos montos son calculados en
              dólares de los Estados Unidos de América y el pago puede ser
              realizado en la moneda de preferencia del usuario, de acuerdo a su
              moneda local u opción de pago disponible para el usuario. Estos
              pagos no serán cancelables ni reembolsables, salvo que la política
              de cancelación de cada Sicólogo así lo disponga. Tanto el Usuario
              como el Sicólogo son los únicos responsables del pago de todos los
              impuestos y tasas que se apliquen a tu actividad Sicólogo.
              <br />
              En el supuesto que el Usuario o Sicólogo incumplan sus
              obligaciones de pago, WIBOF podrá limitar el acceso a los
              servicios de forma inmediata hasta su satisfacción completa.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">5.Duración</h1>
            <p className="font-light text-justify">
              Este acuerdo tendrá una duración indefinida salvo que terminemos o
              suspendamos la relación por incumplimiento del usuario, incluida
              la eliminación o suspensión de la cuenta sin previo aviso o te des
              de baja de los servicios, o WIBOF deje de prestar los servicios
              descritos.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">6. Propiedad Intelectual</h1>
            <p className="font-light text-justify">
              Todo el contenido que integra la Plataforma es propiedad de WIBOF.
              Este contenido está protegido por los derechos de propiedad
              intelectual e industrial. Todo el contenido, visible y no visible,
              disponible en la Plataforma, incluyendo, pero sin limitarse a los
              textos, gráficas, imágenes, logotipos, iconos, software, códigos
              de programación, audiovisuales, y cualquier otro material, se
              encuentra protegidos por las leyes de propiedad intelectual, y su
              uso ha de ser previamente autorizado por WIBOF. El uso no
              autorizado de este contenido puede constituir una violación de las
              leyes de derecho de autor, propiedad industrial, regulaciones
              sobre competencia desleal, u otras legislaciones aplicables.
              <br />
              Durante la vigencia de este acuerdo, y sujeto al pago de las
              tarifas correspondientes y al cumplimiento de los Términos, se te
              concede una licencia de uso personal, no exclusiva, no
              transmisible ni sublicenciable, limitada y revocable, para
              utilizar los servicios y las funcionalidades previstas en la
              Plataforma.
              <br />
              Todo el contenido creativo o de otra manera cualquier contenido
              protegible por derechos de propiedad intelectual que se encuentre
              disponible en WIBOF (textos, audios, videos, imágenes) y que son
              propiedad de terceros, son titularidad exclusiva de su autor,
              creador o realizador, y se encuentran protegidos por derechos de
              autor o propiedad industrial, según sea el caso
              <br />
              En el supuesto de existir material presente en la Plataforma que
              no sea propiedad de WIBOF, como vídeos incrustados en la
              Plataforma, entre otros, éste podrá ser utilizado por WIBOF al
              poseer las autorizaciones o licencias necesarias para su uso. En
              ningún caso la publicación de material protegido por los Derechos
              de Propiedad Intelectual de terceras partes podrá entenderse como
              una licencia para que el Usuario o el Sicólogo puedan utilizar
              dicho material o se confiera derecho alguno sobre los mismos.
              <br />
              Con la aceptación de estos términos y condiciones, usted otorga a
              WIBOF una licencia no exclusiva, perpetua, gratuita, universal e
              irrevocable para uso del contenido introducido por usted al
              utilizar los servicios, si aplicare el caso.
              <br />
              Sin limitar perjuicio de lo anterior, cualquier contenido que
              introduzcas mediante el uso de los servicios puede ser almacenado
              y utilizado por nosotros o cualquier tercera parte que tengamos
              contratada para poder ofrecer los Servicios en cualquier forma y
              con la finalidad de asesorar, proteger nuestros servicios y
              productos, demostrar su funcionalidad y viabilidad a terceras
              partes.
              <br />
              Los Sicólogos, mediante la contratación de los Servicios, otorgan
              a WIBOF un derecho de uso de su nombre comercial y marca, que
              podrán ser utilizados con finalidades comerciales para promocionar
              nuestros productos y servicios en la plataforma o en otras
              comunicaciones públicas o privadas con actuales o posibles
              clientes. En caso de no desear que tu nombre y marca sean
              utilizados para tales fines, por favor contacta con nosotros a
              través del correo electrónico:soporte@wibof.com, comunicando
              que no deseas que tu información pueda ser utilizada para las
              anteriores finalidades.
              <br />
              Todo el contenido de la web www.wibof.com y de la “App” así como
              de cualquier otra aplicación que sea operada, controlada o
              licenciada por WIBOF no puede ser copiado, reproducido, publicado,
              cargado, transmitido o distribuido de ninguna manera sin
              autorización expresa y por escrito de WIBOF.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">7. Protección de Datos</h1>
            <p className="font-light text-justify">
              Conservaremos y procesaremos los datos proporcionados por los
              usuarios, de acuerdo con nuestra Política de Privacidad, que se
              incorpora a estos Términos de servicio.
              <br />
              El Usuario y el Sicólogo son los responsables en todo momento de
              garantizar la precisión y la legalidad de cualquier dato o
              contenido facilitado. Con la aceptación de estos términos y
              condiciones, el usuario declara y garantiza que tiene todos los
              derechos necesarios en su contenido para cargar dicho contenido en
              los servicios y para que se use de todas las maneras contempladas
              en la funcionalidad de los servicios.
              <br />
              WIBOF no es responsable de la eliminación, corrección, alteración,
              destrucción, daño, pérdida, divulgación o falta de almacenamiento
              de su contenido o cualquier otro dato que utilice los Servicios.
              <br />
              En los casos de cuentas corporativas, el Administrador de la
              cuenta debe asegurarse de que todas las partes relevantes
              (incluidos los Usuarios) hayan sido informadas y hayan dado su
              consentimiento a cualquier información personal que tengamos y
              procesemos de acuerdo con nuestra Política de privacidad en nombre
              del Administrador de la cuenta. El Administrador de la cuenta debe
              notificarnos de inmediato de cualquier cambio en la información
              que nos ha proporcionado (incluida la información de contacto).
              Podemos procesar cualquier información personal ingresada en los
              Servicios de acuerdo con nuestra Política de Privacidad.
              <br />
              El usuario garantiza que los datos suministrados son verdaderos,
              exactos y actualizados, y reconoce su responsabilidad por
              cualquier daño o perjuicio, directo o indirecto, que pudiera
              ocasionarse como consecuencia del incumplimiento de tal
              obligación. En el caso de que los datos aportados pertenecieran a
              un tercero, garantizas que has informado a dicho tercero de los
              aspectos contenidos en este documento y que ha obtenido la
              correspondiente autorización para facilitar sus datos a WIBOF para
              los fines señalados.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">8. Confidencialidad</h1>
            <p className="font-light text-justify">
              Los Servicios, así como toda documentación relacionada con el
              acuerdo o facilitada por WIBOF durante nuestra relación será
              considerada como “Información Confidencial de WIBOF”. Tus datos de
              contacto y los datos de tus clientes, trabajadores y/o terceros
              contratantes que puedas introducir al utilizar los Servicios será
              considerada “Tu Información Confidencial”. Cada parte mantendrá el
              secreto de la información confidencial de la otra parte y sólo
              podrá ser utilizada en cumplimiento del Acuerdo, y no será
              comunicada a terceras partes salvo que sea requerido por una ley.
              En este caso, la parte que deba comunicar la información
              confidencial de la otra parte comunicará a la otra parte dicho
              hecho de forma previa a su revelación, facilitando toda solicitud
              o requerimiento recibido. La información que nos facilites podrá
              ser tratada por WIBOF según descrito en la Política de Privacidad,
              que se incluyen en los documentos que conforman el Acuerdo.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">9. Responsabilidades</h1>
            <p className="font-light text-justify">
              Al aceptar los Términos, aceptas y reconoces que la Plataforma se
              presenta “as is” (tal cual), sin que WIBOF pueda garantizar su
              continua y total disponibilidad en todo momento o que no contenga
              fallos o errores que puedan afectar a su rendimiento. WIBOF se
              reserva la facultad de actualizar o disponer de modo distinto el
              contenido presente en la Plataforma, pudiendo afectar
              momentáneamente la disponibilidad de las funcionalidades, sin que
              esta pueda ser entendido como responsabilidad o incumplimiento de
              WIBOF, causa de resolución del acuerdo ni de un descuento en el
              precio pagado.
              <br />
              En ningún caso, WIBOF será responsable por las interrupciones o
              errores que pudieran ocasionarse en el momento de acceder o
              navegar al utilizar la Plataforma, así como de los problemas que
              puedan causarse durante la conexión a la red. Tampoco será
              responsable por los enlaces contenidos en la plataforma que
              redireccionen a terceras páginas que no sean propiedad de WIBOF.
              <br />
              WIBOF excluye toda responsabilidad que se pudiera derivar de
              interferencias, omisiones, interrupciones, virus informáticos,
              averías telefónicas o desconexiones en el funcionamiento operativo
              del sistema electrónico, motivado por causas ajenas a WIBOF.
              También excluye cualquier responsabilidad que pudiera derivarse
              por retrasos o bloqueos en el funcionamiento operativo del sistema
              electrónico causado por deficiencias o sobrecarga en las líneas
              telefónicas o en internet, así como de daños causados por terceras
              personas mediante intromisiones ilegítimas fuera del control de
              WIBOF.
              <br />
              WIBOF no garantiza que la Plataforma, o los servicios prestados a
              través de ella, sean satisfactorios para todos los Usuarios o
              Sicólogos y no será responsable de la falsedad de las
              manifestaciones realizadas por Usuarios o Sicólogos a través de la
              Plataforma.
              <br />
              El usuario se compromete a no introducir programas, virus,
              applets, macros, controles ActiveX o cualquier otro dispositivo
              lógico o secuencia de caracteres que causen o sean susceptibles de
              causar cualquier tipo de alteración en los sistemas informáticos
              de WIBOF o de terceros.
              <br />
              El Usuario se obliga a usar los contenidos de forma diligente,
              correcta y lícita y, en particular, se compromete a abstenerse de
              utilizar los contenidos con fines contrarios a la ley y/o contra
              el orden público; reproducir o copiar, permitir el acceso del
              público a través de cualquier modalidad de comunicación pública,
              distribuir, transformar o modificar los contenidos, sin la
              autorización previa del titular de los correspondientes derechos.
              <br />
              El Usuario y el Sicólogo son los únicos responsables de las
              infracciones en las que puedan incurrir o de los perjuicios que
              pueda causar a WIBOF o a terceros por la utilización de la
              Plataforma, quedando WIBOF exonerada de cualquier clase de
              responsabilidad que se pudiera derivar por las acciones realizadas
              por el usuario. El usuario es el único responsable frente a
              cualquier reclamación o acción legal, judicial o extrajudicial,
              iniciada por terceras personas contra WIBOF basada en la
              utilización por el Usuario o el Sicólogo de los servicios
              ofrecidos por WIBOF. En este caso, el Usuario y el Sicólogo
              asumirán cuantos gastos, costes e indemnizaciones sean irrogados a
              WIBOF con motivo de tales reclamaciones o acciones legales.
              <br />
              Asimismo, el Usuario y el Sicólogo serán los únicos responsables
              por las informaciones que incluyan y faciliten mediante el uso de
              los servicios prestados por WIBOF a través de la Plataforma. En
              especial, WIBOF no será responsable por los actos u omisiones de
              los Sicólogos en el uso de los servicios. Los terapeutas son los
              únicos responsables de los usos que le den a los servicios. Está
              última responsabilidad también aplica a Usuarios por los usos u
              omisiones durante el uso de la Plataforma.
              <br />
              Como se ha descrito a lo largo de estos Términos, WIBOF se limita
              a prestar los servicios de la plataforma y entorno tecnológico
              para que Usuarios y Sicólogos puedan llevar a cabo sus
              comunicaciones de forma privada y confidencial, sin tener
              implicación alguna en ellas. Por esta razón, WIBOF no podrá ser
              considerada responsable de los usos que Usuarios y Sicólogos
              realicen dentro del entorno ofrecido. Por consecuencia, WIBOF no
              ejercerá control alguno sobre los contenidos e informaciones que
              se viertan dentro del entorno privado Usuario/ Sicólogo ni tendrá
              obligación alguna de controlar ni revisarlos. Tanto el Usuario
              como el Sicólogo exoneran a WIBOF de cualquier responsabilidad que
              pueda derivarse del uso de la Plataforma por parte de sus usuarios
              registrados. Sin perjuicio de lo anterior, WIBOF podrá aplicar
              medidas de seguridad encaminadas a prevenir el fraude en la
              utilización de la Plataforma.
              <br />
              Tanto el Usuario como el Sicólogo aceptan defender, indemnizar y
              eximir a WIBOF de toda reclamación, pérdida y daño, demanda,
              investigación gubernamental, multa, acción, daño, acuerdo,
              pérdida, responsabilidad, coste y gasto (incluidos los honorarios
              de abogados) por cualquier incumplimiento de tus representaciones,
              garantías, uso de los servicios, uso contrario a los Términos y la
              normativa de aplicación, en concreto y sin limitación, violaciones
              de la normativa de protección de datos y normativa laboral,
              violación de la normativa de propiedad intelectual o violación de
              derechos de terceras partes, así como de cualquier violación de
              los Términos.
              <br />
              WIBOF se reserva el derecho, a su propio coste, de asumir la
              defensa y el control exclusivos de cualquier asunto por el que el
              Usuario o el Sicólogo deban indemnizar a WIBOF y acepta cooperar
              con la defensa de estas reclamaciones.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">10. Incumplimientos</h1>
            <p className="font-light text-justify">
              En el caso de que el usuario incumpla cualquiera de las
              obligaciones descritas en los presentes Términos, o en los
              documentos relativos al acuerdo con WIBOF, reconoce expresamente
              el derecho de WIBOF a limitar el acceso a los Servicios o a la
              terminación del acuerdo, si no se corrige el incumplimiento en el
              plazo de diez (10) días desde su notificación por escrito al
              correo electrónico facilitado en el formulario de registro. El
              cambio de dirección de correo electrónico sin notificar de su
              cambio a WIBOF supondrá que la comunicación se considere entregada
              a la dirección inicialmente comunicada a efectos del cómputo de
              los plazos.
              <br />
              Especialmente y sin limitación, se considerará incumplimiento los
              siguientes actos: no cumplir con las obligaciones de pago, aportar
              documentación fraudulenta para el registro y uso de WIBOF, hacer
              uso de la Plataforma de forma contraria a los términos y
              condiciones de la Plataforma o a la legislación vigente, ceder el
              uso de la Plataforma a terceros, el uso de la Plataforma por
              usuarios distintos a los registrados por el usuario y/o el
              sicólogo permitir a terceros el uso de las credenciales de un
              usuario registrado, violación de los derechos de propiedad
              intelectual.
              <br />
              En caso de que WIBOF tenga indicios de cualquier incumplimiento de
              los descritos precedentemente, podrá suspender el acceso a los
              servicios de forma inmediata, sin que el usuario tenga derecho a
              reclamar ningún tipo de indemnización por dicha suspensión.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">11. Seguridad</h1>
            <p className="font-light text-justify">
              Adaptamos y actualizamos las medidas de seguridad administrativas,
              técnicas y físicas para contribuir a la protección de la
              información de los usuarios e impedir que ésta sea destruida o
              modificada sin autorización o que se acceda a la misma sin
              permiso. Los cortafuegos, la concesión de permisos según
              funciones, almacenaje encriptado y los controles de acceso a la
              información son algunas de las medidas utilizadas para proteger su
              información. Si tienes motivos para creer que las credenciales de
              tu Cuenta han sido objeto de pérdida, sustracción, apropiación
              indebida o están en riesgo por algún otro motivo, si tiene la
              certeza o sospecha que se ha utilizado su cuenta sin autorización,
              ponte en contacto con nosotros inmediatamente.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">
              12. Modificaciones de los presentes términos y condiciones.
            </h1>
            <p className="font-light text-justify">
              WIBOF se reserva el derecho de modificar los presentes Términos en
              cualquier momento con arreglo a esta disposición, publicando la
              versión revisada de esta en la Plataforma. Asimismo, WIBOF podrá
              notificar a sus usuarios las modificaciones mediante correo
              electrónico, como mínimo, siete (7) días antes de que éstas entren
              en vigor. Si estás en desacuerdo con la versión revisada de la
              Política de privacidad, podrás cancelar tu Cuenta. Si no cancelas
              tu Cuenta antes de la fecha de entrada en vigor de la versión
              revisada de la Política de Privacidad, el acceso continuado que
              realices, así como el uso continuado de la misma y de los
              Servicios quedarán sujetos a la última versión revisada.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">13. Seguridad</h1>
            <p className="font-light text-justify">
              Adaptamos y actualizamos las medidas de seguridad administrativas,
              técnicas y físicas para contribuir a la protección de la
              información de los usuarios e impedir que ésta sea destruida o
              modificada sin autorización o que se acceda a la misma sin
              permiso. Los cortafuegos, la concesión de permisos según
              funciones, almacenaje encriptado y los controles de acceso a la
              información son algunas de las medidas utilizadas para proteger su
              información. Si tienes motivos para creer que las credenciales de
              tu Cuenta han sido objeto de pérdida, sustracción, apropiación
              indebida o están en riesgo por algún otro motivo o si sabe o
              sospecha que se ha utilizado su cuenta sin autorización, ponte en
              contacto con nosotros inmediatamente.
            </p>
          </div>
          <div className="max-w-xl mt-4">
            <h1 className="text-left font-bold">14. Generalidades</h1>
            <p className="font-light text-justify">
              <strong>WIBOF</strong> utiliza cookies para mejorar el desempeño
              de la Plataforma y proporcionar al usuario una mejor experiencia.
              Al utilizar la Plataforma, el usuario acepta que WIBOF utilizará
              cookies para analizar el uso que hace cada usuario de la
              Plataforma, establecer sus preferencias, el tipo de contenido
              consultado, entre otras variables que nos ayudan a brindar una
              mejor experiencia al usuario y agilizar cada proceso requerido
              dentro de la Plataforma. Esto nos permite personalizar el
              servicio, ofreciendo sugerencias y recomendaciones acorde con el
              perfil de cada usuario.
              <br />
              Los presentes Términos y Condiciones, con las modificaciones que
              en el futuro se realicen a la plataforma a exclusivo criterio de
              WIBOF, constituyen el único acuerdo entre el usuario y WIBOF.
              <br />
              La invalidez o inaplicabilidad de cualquiera de los presentes
              Términos y Condiciones no afectará la validez o la aplicabilidad
              de los demás derechos, términos y condiciones.
              <br />
              En caso de que el usuario desee contactarse con WIBOF a los fines
              de obtener más información respecto a la plataforma o a los
              presentes Términos y Condiciones, deberá hacerlo enviando un
              correo electrónico a soporte@wibof.com
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div>
          <div className="mt-12">
            <h1 className="text-lg font-bold">Política de Privacidad</h1>
            <h2 className="text-left">Fecha de vigencia: 2023</h2>
          </div>
          <div className="max-w-xl mt-12">
            <p className="font-light text-justify">
              WIBOF GROUP LLC, como empresa gestora y responsable de la APP
              WIBOF (en lo adelante "nosotros", "nos" o "nuestro"), reconoce el
              derecho inalienable de toda persona a la privacidad, así como la
              privacidad y la seguridad de la información que comparte con
              nosotros a través de WIBOF APP (en lo adelante (“App”). Es nuestro
              interés que se sienta cómodo y seguro al utilizar nuestra “App”,
              por lo que explicamos detalladamente cómo recopilamos, usamos y
              mantenemos información personal cuando utilizas nuestra aplicación
              de salud mental. En esta política de privacidad también explicamos
              tus derechos y opciones con respecto a tu información personal y
              el tratamiento que le damos a la misma. Nos comprometemos a
              proteger tu privacidad y cumplir con las leyes aplicables de
              protección de datos, incluido el Reglamento General de Protección
              de Datos (GDPR, por sus siglas en inglés) y la Ley de Privacidad
              del Consumidor de California (CCPA).
              <br />
              La presente Política es aplicable a la información identificable
              como personal (en lo adelante, “Datos”) que podría ser colectada
              por nosotros en nuestra “App”. Esta Política no se aplica a otras
              entidades que no controlamos o personas que no son nuestros
              empleados o agentes o que no están bajo nuestro control.
              <br />
              Al utilizar la “App” está aceptando los términos de esta Política
              de Privacidad (actualizada por última vez el 1ro de Septiembre,
              2023.
              <br />
              Con la aceptación de esta política de privacidad, el usuario
              garantiza que los datos suministrados son exactos y verdaderos,
              reconociendo su responsabilidad ante cualquier daño o perjuicio
              que se pudiera ocasionar, de forma directa o indirecta, por
              incumplimiento de esta obligación.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">
              ¿Qué tipo de datos recopilamos? Cómo utilizamos tu información?
            </h1>
            <p className="font-light text-justify">
              Cuando utilizas la “App”, recopilamos automáticamente cierta
              información sobre tu dispositivo, incluida su dirección IP, zona
              horaria y algunas de las cookies que están instaladas en tu
              dispositivo e información sobre cómo interactúas en la “App”. En
              lo adelante nos referimos a esta información recopilada
              automáticamente como "Información del dispositivo".
              <br />
              Para utilizar nuestra “App” usted debe registrarse y proveerse de
              un nombre de usuario y contraseña, conforme se describe en los
              términos y condiciones de uso, es por ello que al hacer uso de la
              “App” podemos recopilar información personal como nombre de
              usuario, contraseña, estado civil; información demográfica, como
              edad, género e orientación sexual, ubicación y/o país de
              residencia; información de contacto, como dirección, correo
              electrónico y teléfono de contacto; información de seguridad, como
              contacto de emergencia. Esta información es de gran importancia
              dado que nos permite asegurarnos de que el usuario cumple con los
              requisitos de edad mínima para utilizar la aplicación, y nos
              permite ofrecerle un servicio de calidad, personalizado de acuerdo
              a su ubicación geográfica.
              <br />
              La información de contacto almacenada, como número telefónico,
              correo electrónico y contacto de emergencia, es utilizada por
              nosotros para fines de autenticación y contacto, como envío de
              notificaciones, al igual que para establecer comunicación con el
              contacto de emergencia designado por el usuario, en caso de ser
              necesario ante una eventual emergencia.
              <br />
              Podemos utilizar tu información personal para brindar y
              personalizar las funciones y servicios de la “App”, para responder
              a tus consultas y brindar soporte, enviarte información
              administrativa, como actualizaciones y cambios en nuestras
              políticas, correos electrónicos promocionales o boletines
              informativos. Esta información también puede ser utilizada para
              analizar y mejorar el rendimiento, la funcionalidad y la
              experiencia del usuario de la “App” .
              <br />
              La “App” puede solicitar acceso a las notificaciones de su
              dispositivo móvil, para enviar notificaciones relevantes como
              ofertas, recordatorios de citas y acciones a realizar en el
              contexto de la aplicación. El usuario tiene la opción de controlar
              las preferencias de notificaciones, por lo que puede activar o
              desactivar estas notificaciones en cualquier momento.
              <br />
              Si es usted un terapeuta, al registrarte y hacer uso de la “App”
              estaremos solicitando información personal sobre su formación
              académica y experiencia profesional. Esta información nos
              permitirá validar sus credenciales y habilidades profesionales, al
              igual que su acreditación para ejercer la profesión. Solicitaremos
              información acerca de sus áreas de especialidad y tipos de terapia
              que ofrece, para brindar a los usuarios servicios acorde con su
              perfil. Adicionalmente recabamos información acerca de sus
              creencias espirituales para valorar la posibilidad de conflictos o
              inconvenientes respecto de algunas terapias que pudieran
              involucrar creencias o prácticas espirituales específicas.
              <br />
              También podemos recopilar información no personal que no
              identifica directamente al usuario ("Información No Personal"),
              como datos agregados, información estadística y análisis sobre el
              uso de la “App”, que pueden derivarse de su Información Personal.
              <br />
              Cuando se realiza un pago a través de la “APP”, recopilamos cierta
              información adicional a las credenciales del usuario, como
              dirección de facturación, información de pago (incluidos los
              números de tarjetas de crédito o usuarios de Paypal, dirección de
              correo electrónico y número de teléfono, así como números de
              documentos de identificación personal e identificación tributaria,
              si aplica) (“información de pago”).
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">
              ¿Quién tiene acceso a sus datos?
            </h1>
            <p className="font-light text-justify">
              No compartimos con terceros tu información personal, excepto con
              los profesionales de la salud, siempre que hayan sido registrados
              en la “App” y seleccionado por usted para brindarle servicios. Con
              excepción de nuestros administradores, los operadores de la “App”
              y los terapeutas con los que usted se encuentre trabajando, ningún
              tercero puede acceder a tus datos personales.
              <br />
              Podemos compartir tu Información Personal con proveedores de
              servicios de confianza que nos ayudan a operar la “App”, realizar
              nuestra actividad comercial y brindarte servicios. Estos
              proveedores de servicios están autorizados para procesar tu
              Información Personal solo en la medida necesaria para brindar los
              servicios en nuestro nombre y están obligados a proteger tu
              información.
              <br />
              Garantizamos que tu información personal solo es divulgada en los
              casos que así lo exija la ley o en respuesta a solicitudes válidas
              de autoridades gubernamentales.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">
              ¿Para qué fines se utilizan tus datos?
            </h1>
            <p className="font-light text-justify">
              Con los datos almacenados en la “App” somos capaces de
              identificarte como nuestro usuario, lo que nos permite entregar
              avisos y otros materiales de marketing, a la vez que nos ayuda a
              proveerte un servicio personalizado, de calidad, de acuerdo al
              perfil y preferencias de cada usuario. Como visitante, no puedes
              hacer uso de nuestros servicios hasta registrarte como usuario,
              por tanto, estos datos son el único medio para identificarte y
              permitirte utilizar los servicios que proveemos a través de la
              “App”.
              <br />
              Utilizamos la información del dispositivo que recopilamos para
              ayudarnos a detectar posibles riesgos y fraudes, y de manera más
              general, para mejorar y optimizar la “App”, (por ejemplo,
              generando análisis acerca de la interacción de nuestros clientes
              en la “App”).
              <br />
              Tu información también es utilizada para generar datos
              estadísticos que nos ayuden a mejorar los servicios de salud.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">
              Cuánto tiempo WIBOF retiene tu información?
            </h1>
            <p className="font-light text-justify">
              Al momento de crear un usuario en la “App”, almacenamos tus datos
              personales y los conservamos hasta que decidas eliminar tu cuenta
              o suscripción. Mantendremos tu información para nuestros
              registros, a menos que, o hasta que solicites la eliminación de tu
              información.
              <br />
              Algunos registros relacionados indirectamente con tu
              comportamiento en la “App”, como citas de consulta, lapso que ha
              durado su registro, interacción con la “manada” y otros datos no
              personales, podrían permanecer en nuestros registros. Por la
              naturaleza de esta información y puesto que está indivisiblemente
              ligada a la interacción con otros servicios, no podrá ser
              eliminada a petición del usuario.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">
              Lo que no hacemos con tus datos personales
            </h1>
            <p className="font-light text-justify">
              La información personal del usuario no es compartida con terceros
              sin su consentimiento explícito, salvo en los casos que sea
              requerido por agencias gubernamentales o constituya una exigencia
              legal.
              <br />
              No compartimos ni divulgamos, vendemos, alquilamos o de otra
              manera proporcionamos tus datos personales a otras compañías para
              la comercialización de sus propios productos o servicios.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">Derechos del usuario.</h1>
            <p className="font-light text-justify">
              El usuario tiene derecho a acceder a la información personal
              almacenada en la “App”, y solicitar la corrección de cualquier
              información errónea que haya sido almacenada por nosotros. El
              usuario puede y tiene derecho a solicitar que su información
              personal se corrija, actualice o elimine. Si deseas ejercer este
              derecho, contáctanos a través de la dirección de correo
              electrónico:{" "}
              <a
                className="text-blue-600 underline"
                href="mailto:soporte@wibof.com"
              >
                soporte@wibof.com
              </a>{" "}
              o enviando una solicitud a la dirección postal que aparece al
              final de este documento.
              <br />
              Una vez recibida la solicitud del usuario, la información es
              eliminada de nuestros registros en un plazo no mayor de cuarenta y
              cinco días (45), a partir de la fecha efectiva de recepción de
              dicha solicitud.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">
              Protección de la información personal. Procedimiento de violación
              de datos.
            </h1>
            <p className="font-light text-justify">
              Implementamos medidas de seguridad adecuadas para proteger la
              información personal de los usuarios contra accesos no
              autorizados, pérdidas o robos. Adicionalmente, utilizamos métodos
              de encriptación para proteger la información confidencial, como
              los datos financieros de nuestros usuarios.
              <br />
              Si a pesar de todas las medidas y controles se seguridad, se
              produjera una violación de datos como pérdida, alteración,
              divulgación o acceso no autorizado, transmisión de datos
              personales, almacenamiento o procesado de otro modo, se lo
              notificaremos inmediatamente tengamos conocimiento.
              <br />
              Si tiene usted sospecha de que sus datos pudieran estar en riesgo
              de alteración, supresión, pérdida o robo, o si sospechas que se ha
              utilizado sus credenciales sin autorización, póngase en contacto
              con nosotros en la dirección de correo electrónico que figura al
              pie de este documento.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">Modificaciones</h1>
            <p className="font-light text-justify">
              Esta política de privacidad puede ser revisada de forma periódica
              debido a cambios en los servicios ofrecidos, modificación de
              nuestras prácticas o por otras razones operativas, legales o
              reglamentarias.
            </p>
          </div>
          <div className="max-w-xl mt-12">
            <h1 className="text-left font-bold">Contáctenos</h1>
            <p className="font-light text-justify">
              Para obtener más información sobre nuestras prácticas de
              privacidad, si tiene preguntas o si desea presentar una queja,
              comuníquese con nosotros por correo electrónico a
              soporte@wibof.com o por correo postal, utilizando los detalles
              que se proporcionan a continuación: NIMO GROUP LLC., Dirección:{" "}
              <br />
              1007 N Orange St., 4th Floor, suite 1382, Wilmington, Delaware,
              19801.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
